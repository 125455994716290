<template>
  <v-app>
    <v-app-bar dark app color="teal">
      <v-toolbar-title class="headline text-uppercase">
        <span>Web-Yodaforex Connect - Registre</span>
      </v-toolbar-title>
      <v-spacer/>
      <y-auth-button v-model="isAuth"/>
    </v-app-bar>

    <v-main>
      <router-view v-if="isAuth"/>
      <v-container v-else>
        <div class="d-flex justify-center">
          <v-progress-linear indeterminate/>
        </div>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isAuth: false
    }
  }
};
</script>

