module.exports = {
  env: 'production',
  apiUrl: '',
  authConfig: {},
  services: {
    authApiBaseUrl: "https://auth.api.yodaforex.cloud",
    licenseApiBaseUrl: "https://license.api.yodaforex.cloud",
    webcoApiBaseUrl: "https://web-connect.api.yodaforex.fr",
  },
  webSocketConnectedMediumsEndpoint: process.env.VUE_APP_SELF_WEBSOCKET_BASE_URL + '/ws/v1/migrations'
}
