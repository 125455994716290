<template>
  <span class="caption">Dernière récupération du registre : <span v-if="!loading || !firstLoading">{{ lastFetchComputed }}</span><v-skeleton-loader
      v-else type="text@1" class="d-inline-block" width="60"/></span>
</template>

<script>
export default {
  name: "RegistryFetchDate",
  data: () => ({
    loading: false,
    error: false,
    lastDate: null,
    firstLoading: true
  }),
  computed: {
    lastFetchComputed() {
      return (this.lastDate == null || this.lastDate.length === 0) ? 'Jamais' : this.$moment(this.lastDate).calendar();
    }
  },
  timers: {
    refresh: {time: 5000, autostart: true, repeat: true, immediate: true, isSwitchTab: true}
  },
  methods: {
    refresh() {
      this.loading = true;
      this.error = false;
      this.$http.get(this.$config.apiUrl + '/v1/registry/last-fetch-date')
          .then(r => this.lastDate = r.data)
          .catch(() => this.error = true)
          .finally(() => {
            this.loading = false;
            this.firstLoading=false
          });
    }
  }
}
</script>

<style scoped>

</style>
