<template>
  <v-container>
    <v-snackbar color="error" v-model="error" :timeout="5000">Une erreur est survenue</v-snackbar>
    <v-dialog v-model="showForm" max-width="600">
      <registry-entry-card :entry-to-update="selectedEntry" @save="entrySaved" @cancel="entryCanceled"/>
    </v-dialog>
    <v-row>
      <v-col>
        <registry-fetch-date/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <y-company-select outlined label="Filtrer sur la société" v-model="filterOnCompany"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mt-6">
          <v-btn fab absolute top right color="primary" small @click="addEntryClicked">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-data-table
              :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100]}"
              :items-per-page="100"
              :headers="headers"
              :items="filteredItems"
              :loading="loading"
              @click:row="rowClicked"
          >
            <template v-slot:item.status="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="item.activated ? (item.demoMode ? 'amber lighten-2' : 'green') : 'grey lighten-1'"
                          v-bind="attrs" v-on="on">
                    mdi-circle
                  </v-icon>
                </template>
                <span>{{ item.activated ? (item.demoMode ? 'Activé Demo' : 'Activé') : 'Désactivé' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.branchUuid="{ item }">
              <y-branch-label :value="item.branchUuid"/>
            </template>
            <template v-slot:item.companyUuid="{ item }">
              <y-company-label :value="item.companyUuid"/>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | moment('from') }}
            </template>
            <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.createdAt !== item.updatedAt">
                  {{ item.updatedAt | moment('from') }}
              </span>
              <span v-else>—</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegistryEntryCard from "@/components/RegistryEntryCard";
import RegistryFetchDate from "@/components/RegistryFetchDate";

export default {
  name: "RegistryEntryListView",
  components: {RegistryFetchDate, RegistryEntryCard},
  data: () => ({
    filterOnCompany: null,
    selectedEntry: null,
    showForm: false,
    loading: false,
    error: false,
    items: [],
    headers: [
      {text: "", value: "status", sortable: false},
      {text: "Agence", value: "branchUuid"},
      {text: "Sociéte", value: "companyUuid"},
      {text: "Description", value: "description"},
      {text: "Création", value: "createdAt"},
      {text: "Mise à jour", value: "updatedAt"}
    ],
  }),
  computed: {
    filteredItems() {
      return this.filterOnCompany ? this.items.filter((e) => e.companyUuid === this.filterOnCompany.uuid) : this.items
    }
  },
  mounted() {
    this.fetchList();
  },
  watch: {
    showForm(newVal) {
      if (!newVal) {
        this.selectedEntry = null;
      }
    }
  },
  methods: {
    fetchList() {
      this.loading = true;
      this.error = false;
      this.$http.get(this.$config.apiUrl + "/v1/registry")
          .then((d) => this.items = d.data)
          .catch(() => this.error = true)
          .finally(() => this.loading = false)
    },
    addEntryClicked() {
      this.selectedEntry = null;
      this.showForm = true;
    },
    rowClicked(row) {
      this.selectedEntry = row;
      this.showForm = true;
    },
    entrySaved() {
      this.selectedEntry = null;
      this.showForm = false;
      this.fetchList();
    },
    entryCanceled() {
      this.selectedEntry = null;
      this.showForm = false;
    }
  }
}
</script>

<style scoped>

</style>
