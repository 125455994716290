import Vue from 'vue'
import VueRouter from 'vue-router'
import RegistryEntryListView from '../views/RegistryEntryListView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/registry',
    name: 'RegistryEntryListView',
    component: RegistryEntryListView
  }
]

const router = new VueRouter({
  routes
})

export default router
