var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  env: 'development',
  apiUrl: 'http://localhost:8082', // Local seulement
  authConfig: {
    devMode: true
  },
  webSocketConnectedMediumsEndpoint: 'ws://192.168.1.1:8082/ws/v1/migrations'
})
