var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-snackbar',{attrs:{"color":"error","timeout":5000},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v("Une erreur est survenue")]),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('registry-entry-card',{attrs:{"entry-to-update":_vm.selectedEntry},on:{"save":_vm.entrySaved,"cancel":_vm.entryCanceled}})],1),_c('v-row',[_c('v-col',[_c('registry-fetch-date')],1)],1),_c('v-row',[_c('v-col',[_c('y-company-select',{attrs:{"outlined":"","label":"Filtrer sur la société"},model:{value:(_vm.filterOnCompany),callback:function ($$v) {_vm.filterOnCompany=$$v},expression:"filterOnCompany"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mt-6"},[_c('v-btn',{attrs:{"fab":"","absolute":"","top":"","right":"","color":"primary","small":""},on:{"click":_vm.addEntryClicked}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-data-table',{attrs:{"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50, 100]},"items-per-page":100,"headers":_vm.headers,"items":_vm.filteredItems,"loading":_vm.loading},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.activated ? (item.demoMode ? 'amber lighten-2' : 'green') : 'grey lighten-1'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.activated ? (item.demoMode ? 'Activé Demo' : 'Activé') : 'Désactivé'))])])]}},{key:"item.branchUuid",fn:function(ref){
var item = ref.item;
return [_c('y-branch-label',{attrs:{"value":item.branchUuid}})]}},{key:"item.companyUuid",fn:function(ref){
var item = ref.item;
return [_c('y-company-label',{attrs:{"value":item.companyUuid}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'from'))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt !== item.updatedAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from'))+" ")]):_c('span',[_vm._v("—")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }