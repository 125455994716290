<template>

  <v-stepper v-model="processIndex" vertical>

    <v-snackbar top color="error" v-model="error" :timeout="8000">
      Une erreur est survenue durant la procédure. <pre>{{errorMessage}}</pre>
    </v-snackbar>

    <v-stepper-step :complete="processIndex > 1" step="1">Confirmation de l'agence cible</v-stepper-step>
    <v-stepper-content step="1">
      <v-card color="blue-grey lighten-5" class="mb-12">
        <v-card-text>
        <y-branch-select :value="processes.targetConfirmation.selectedBranch" disabled/>

        <v-radio-group v-if="!sisterBranch" v-model="processes.targetConfirmation.sales">
          <v-radio label="Publication des cours seulement" :value="false"/>
          <v-radio label="Publication et prise de commande en ligne" :value="true"/>
        </v-radio-group>
        <span v-else>Activation de la récupération des commandes en ligne seulement, pas d'envoi de cours.</span>

        </v-card-text>
      </v-card>
      <v-btn :disabled="complete" color="primary" @click="start">Suivant</v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="processIndex > 2" step="2" :rules="[()=>!processes.fetchingYodaforexLicense.error]">Récupération de la license Yodaforex</v-stepper-step>

    <v-stepper-step :complete="processIndex > 3" step="3" :rules="[()=>!processes.creatingYodaforexKey.error]">
      Génération de la clé Yodaforex
      <small v-if="processes.creatingYodaforexKey.generatedKey" class="ml-2">{{processes.creatingYodaforexKey.generatedKey}}</small>
      <small v-if="processes.creatingYodaforexKey.alreadyGenerated" class="ml-2">Clé déjà présente sur la licence, pas nécessaire de re-générer une nouvelle clé.</small>
    </v-stepper-step>

    <v-stepper-step :complete="processIndex > 4" step="4" :rules="[()=>!processes.updateLicense.error]">Ajout de la clé à la license et modification des options web</v-stepper-step>

    <v-stepper-step v-if="!sisterBranch" :complete="complete" step="5" :rules="[()=>!processes.creatingWebmasterKey.error]">Génération de la clé webmaster</v-stepper-step>
    <v-stepper-content v-if="!sisterBranch" step="5">
      <v-card>
        <v-card-subtitle>Création d'une clé {{webmasterKeyRole}}<v-progress-circular class="ml-4" size="16" indeterminate v-if="processes.creatingWebmasterKey.loading"/></v-card-subtitle>
        <v-card-text v-if="processes.creatingWebmasterKey.error">
          <v-alert type="error">Une erreur est survenue</v-alert>
        </v-card-text>
        <v-card-text v-else-if="processes.creatingWebmasterKey.generatedKey">
          <v-text-field label="Clé à transmettre au webmaster" readonly v-model="processes.creatingWebmasterKey.generatedKey"/>
        </v-card-text>
      </v-card>
    </v-stepper-content>

    <v-alert v-if="complete" type="success">Procédure terminée !</v-alert>
  </v-stepper>
</template>

<script>

export default {
  name: "MagicWebco",
  props: {
    branchUuid: String,
    companyUuid: String,
    sisterBranch: { // Indique si l'agence traitée est l'agence principale ou une agence "soeur" qui ne pourra que récupérer les commandes
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    processIndex: 1,
    complete: false,
    error: false,
    errorMessage: null,

    processes: {
      targetConfirmation: {
        selectedBranch: null,
        selectedBranchUuid: "",
        selectedCompanyUuid: "",
        sales: false
      },
      fetchingYodaforexLicense: {
        error: false,
        branch: null,
      },
      creatingYodaforexKey: {
        error: false,
        generatedKey: null,
        alreadyGenerated: false
      },
      updateLicense: {
        error: false,
      },
      creatingWebmasterKey: {
        loading: false,
        error: false,
        generatedKey: null
      }
    },
  }),
  watch: {
    "processes.targetConfirmation.selectedBranch"(v) {
      if (v.uuid) {
        this.processes.targetConfirmation.selectedBranchUuid = v.uuid;
        this.processes.targetConfirmation.selectedCompanyUuid = v.companyUuid;
      }
    },
  },
  computed: {
    webmasterKeyRole() {
      return this.processes.targetConfirmation.sales ? 'website-sales' : 'website-booking';
    }
  },
  mounted() {
    this.processes.targetConfirmation.selectedBranch = this.branchUuid;
    this.processes.targetConfirmation.selectedBranchUuid = this.branchUuid;
    this.processes.targetConfirmation.selectedCompanyUuid = this.companyUuid;
  },
  methods: {
    async start() {
      try {
        this.processIndex = 2;
        await this.fetchYodaforexLicense();
        this.processIndex = 3;
        await this.createYodaforexKey();
        this.processIndex = 4;
        await this.updateLicense();
        if (!this.sisterBranch) { // Pas besoin de créer de jeton webmaster pour les agences soeurs
          this.processIndex = 5;
          await this.createWebmasterKey();
        }
        this.complete = true;
      } catch (e) {
        this.error = true;
        this.errorMessage = e;
      }
    },
    fetchYodaforexLicense() {
      return new Promise((resolve, reject) => {
        this.$http.get(this.$config.services.licenseApiBaseUrl+"/v1/companies/"+this.processes.targetConfirmation.selectedCompanyUuid+"/branches/"+this.processes.targetConfirmation.selectedBranchUuid)
            .then((r)=>{
              this.processes.fetchingYodaforexLicense.branch = r.data;
              resolve();
            }).catch(reject);
      })
    },
    createYodaforexKey() {
      return new Promise((resolve, reject) => {
        if (this.processes.fetchingYodaforexLicense.branch.license.conf['options.web.api.token']) {
          this.processes.creatingYodaforexKey.alreadyGenerated = true;
          resolve();
        } else {
          this.$http.post(this.$config.services.authApiBaseUrl+"/v1/tokens", {
            companyUuid: this.processes.targetConfirmation.selectedCompanyUuid,
            branchUuid: this.processes.targetConfirmation.selectedBranchUuid,
            serviceName: "web-yfx-connect",
            role: "yodaforex",
            label: "MagicWebco Yfx pour " + this.processes.fetchingYodaforexLicense.branch.name
          }).then((r)=>{
            this.processes.creatingYodaforexKey.generatedKey = r.data.tokenString;
            resolve();
          }).catch(reject);
        }
      })
    },
    updateLicense() {
      let payload = {
        "options.web.api.url": this.$config.services.webcoApiBaseUrl,
        "options.web.publication.actif": !this.sisterBranch, // On ne veut PAS de publication chez les agences soeurs
        "options.web.vente.actif": !!this.processes.targetConfirmation.sales || this.sisterBranch // Les sister branches ont forcément les commandes activées, c'est leur rôle
      };
      if (!this.processes.creatingYodaforexKey.alreadyGenerated) { // Si on a généré une nouvelle clé, on l'indique aussi
        payload["options.web.api.token"] = this.processes.creatingYodaforexKey.generatedKey;
      }
      return this.$http.post(this.$config.services.licenseApiBaseUrl+"/v1/branches/"+this.processes.targetConfirmation.selectedBranchUuid+"/license/conf-light", payload)
    },
    createWebmasterKey() {
      return new Promise((resolve, reject) => {
        this.$http.post(this.$config.services.authApiBaseUrl+"/v1/tokens", {
          companyUuid: this.processes.targetConfirmation.selectedCompanyUuid,
          branchUuid: this.processes.targetConfirmation.selectedBranchUuid,
          serviceName: "web-yfx-connect",
          role: this.webmasterKeyRole,
          label: "MagicWebco Web pour " + this.processes.fetchingYodaforexLicense.branch.name
        }).then((r)=>{
          this.processes.creatingWebmasterKey.generatedKey = r.data.tokenString;
          resolve();
        }).catch(reject);
      })
    }


  }

}
</script>

<style scoped>

</style>
