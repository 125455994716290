<template>
  <v-form ref="form" @submit.prevent="validate" v-model="isFormValid">
    <v-container>
      <h3>Instance</h3>
      <v-switch v-model="form.activated" label="Activer l'instance"/>
      <y-branch-select v-if="!update" v-model="selectedBranch" label="Agence pour remplissage auto."/>
      <v-text-field :disabled="true" v-model="form.branchUuid" :rules="rules.notEmpty" label="UUID Agence"/>
      <v-text-field :disabled="true" v-model="form.companyUuid" :rules="rules.notEmpty" label="UUID Société"/>
      <v-text-field v-model="form.description" :rules="rules.notEmpty" label="Description"/>
      <h3>Base de données</h3>
      <v-text-field v-model="form.databaseCredentials.host" :rules="rules.notEmpty" label="Hôte" placeholder="sql.yodatech.com"/>
      <v-text-field v-model="form.databaseCredentials.port" type="number" :rules="rules.notEmptyNum" label="Port" placeholder="3306"/>
      <v-text-field v-model="form.databaseCredentials.database" :rules="rules.notEmpty" label="Nom de la base" placeholder="ma_db"/>
      <v-btn @click="addConnectionOptions" text small>Rajouter les options MySQL habituelles</v-btn>
      <v-text-field v-model="form.databaseCredentials.username" :rules="rules.notEmpty" label="Nom d'utilisateur" placeholder="mon_user"/>
      <v-text-field v-model="form.databaseCredentials.password" label="Mot de passe" placeholder="P@$$w0rd"/>
      <test-connection-button class="mb-8" :host="form.databaseCredentials.host" :port="form.databaseCredentials.port" :database="form.databaseCredentials.database" :username="form.databaseCredentials.username" :password="form.databaseCredentials.password"/>
      <h3>Configuration</h3>
      <v-switch v-model="form.demoMode" label="Instance de démonstration"/>
      <v-switch v-model="form.authorizeAjax" label="Autoriser les requêtes AJAX"/>
      <v-switch v-model="form.authorizeStockRetrieving" label="Autoriser la récupération du stock"/>
      <v-switch v-model="form.ordersWithTargetedBranch" label="Gestion des commandes multi-agences"/>
      <v-switch v-if="form.ordersWithTargetedBranch" v-model="form.ordersWithTargetedBranchStrictMode" label="Rendre obligatoire l'identifiant de l'agence à la création de commande"/>

      <v-card v-if="form.ordersWithTargetedBranch">
        <v-card-text>
        <div class="d-flex flex-row align-center mb-4">
          <y-branch-select outlined="" v-model="sisterBranchToAdd" label="Ajouter une agence soeur"/>
          <v-btn class="ml-2" icon outlined large @click="addSisterBranch(sisterBranchToAdd)" :disabled="!sisterBranchToAdd" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
        </div>

        <div>
          <h4>Agences soeurs</h4>
          <span v-for="branchUuid of form.branchesAllowedToFetchOrders" class="d-flex flex-row align-center">
            <y-branch-label :value="branchUuid">
              <template v-slot:default="{branch}">
                {{branch.name}} ({{branch.shopName}})<span v-if="branch.yodatechName">&nbsp;- {{branch.yodatechName}}</span>
              </template>
            </y-branch-label>
            <v-btn icon small color="purple" @click="$emit('magic-webco-on-sister-branch', branchUuid)"><v-icon small>mdi-magic-staff</v-icon></v-btn>
            <v-btn icon small @click="deleteSisterBranch(branchUuid)"><v-icon small>mdi-delete</v-icon></v-btn>
          </span>
          <span class="orange--text" v-if="sisterBranchToAdd">Pour valider l'agence sélectionnée, cliquer sur le +</span>
          <span class="caption" v-if="!form.branchesAllowedToFetchOrders || form.branchesAllowedToFetchOrders.length === 0">Aucune agence soeur</span>
        </div>
        </v-card-text>
      </v-card>
      <v-switch v-model="form.useUTCTime" label="Utiliser des dates en UTC (GMT+0)"/>
      <v-autocomplete v-if="!form.useUTCTime" :items="availableTimeZones" v-model="form.timezone" label="Fuseau horaire"/>
    </v-container>
  </v-form>
</template>

<script>
import timezones from "timezones-list"
import TestConnectionButton from "@/components/TestConnectionButton";

export default {
  name: "RegistryEntryForm",
  components: {TestConnectionButton},
  props: ['value', 'update'],
  data: () => ({
    initialState: {},
    emptyState: {},
    selectedBranch: null,
    form: {
      branchUuid: "",
      companyUuid: "",
      description: "",
      demoMode: false,
      activated: false,
      authorizeAjax: false,
      authorizeStockRetrieving: false,
      ordersWithTargetedBranch: false,
      ordersWithTargetedBranchStrictMode: false,
      branchesAllowedToFetchOrders: [],
      useUTCTime: false,
      timezone: null,
      databaseCredentials: {
        host: "",
        username: "",
        password: "",
        database: "",
        port: ""
      }
    },
    rules: {
      notEmpty: [(v) => (!!v && v.length > 0) || 'Ce champ est requis.'],
      notEmptyNum: [(v) => (!!v && v > 0) || 'Ce champ est requis.']
    },
    isFormValid: false,
    availableTimeZones: [],
    sisterBranchToAdd: null
  }),
  mounted() {
    Object.assign(this.emptyState, this.form);
    Object.assign(this.initialState, this.form); // Pour repartir sur une base vierge si on reset alors qu'aucune "value" n'est passée
    this.updateFromProp(this.value);
    this.availableTimeZones = timezones.map((t)=>({text:t.label,value:t.tzCode}));
  },
  watch: {
    selectedBranch(v) {
      if (v) {
        this.form.branchUuid = v.uuid;
        this.form.companyUuid = v.companyUuid;
      }
    },
    value: {
      deep: true,
      handler: function (newVal) {
        this.updateFromProp(newVal);
      }
    },
    form: {
      deep: true,
      handler: function (newVal) {
        if (!newVal.useUTCTime && !newVal.timezone) {
          newVal.timezone = "Europe/Paris"
        }
        this.$emit('input', newVal);
      }
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      return this.isFormValid;
    },
    updateFromProp(val) {
      if (!!val) {
        this.initialState = val;
        Object.assign(this.form, val);
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      } else {
        Object.assign(this.form, this.emptyState);
      }
    },
    reset() {
      this.updateFromProp(this.initialState);
      this.$refs.form.resetValidation();
    },
    addConnectionOptions() {
      this.form.databaseCredentials.database+="?serverTimezone=UTC&zeroDateTimeBehavior=convertToNull"
    },
    deleteSisterBranch(branchUuid) {
      this.form.branchesAllowedToFetchOrders.splice(this.form.branchesAllowedToFetchOrders.indexOf(branchUuid),1);
    },
    addSisterBranch(branch) {
      if (this.form.branchesAllowedToFetchOrders == null) this.form.branchesAllowedToFetchOrders = [];
      if (branch.companyUuid === this.form.companyUuid && this.form.branchUuid !== branch.uuid && this.form.branchesAllowedToFetchOrders.indexOf(branch.uuid) < 0) {
        this.form.branchesAllowedToFetchOrders.push(branch.uuid);
        this.sisterBranchToAdd = null;
      }
    }
  }
}
</script>

<style scoped>

</style>
